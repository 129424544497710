import { gql } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, CircularProgress, Paper, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons-react";
import update from "immutability-helper";
import _, { PropertyPath } from "lodash";
import { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import { BXEngine } from "src/BXEngine";
import { TableItem } from "src/components/BXUI/DataTable/Item";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { Pagination } from "src/components/pagination";
import { useAppTrigger } from "src/features/appState/selectors";
import store from "src/store/store";
import { ElementBaseProps } from "src/types/UIElement";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import axiosServices from "src/utils/axios";
import { getAuthorizationHeader, handleRefetchQueries, handleSetQueryData, returnArrayValue } from "src/utils/generalUtils";
import DraggableRow from "src/views/pages/BuildX/AppBuilder/forms/DraggableRow";
import { getSharedViews } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { replaceBaseUrl, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { ActionButton, useReplaceDataPlaceholders } from "./ActionButton";

/**
 *
 */
type BXDataTableCellType = "numeric" | "text" | "complex";

/**
 * @type BXDataTableColumn
 * description of table column. including header and cell type
 */

export type BXDataTableColumn = {
  openLinkAs: string;
  linkUrl: any;
  headerCellType: string;
  viewBuilderHeaderId: string;
  customHeight: string;
  name: string;
  sortable?: boolean;
  filterable?: boolean;
  cellType?: BXDataTableCellType;
  children: BXDataTableColumn[]; // applies only if cellType is complex
  verticalAlign?: string;
  horizontalAlign?: string;
  source?: string;
  type?: string;
  allowWrap?: boolean;
  allowCopy?: boolean;
  customWidth?: string;
  alignColumn?: string;
};

type DataTableProps = {
  columns: BXDataTableColumn[];
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
    enableMultipleSelection?: string;
    viewName?: string;
    hideViewName?: string;
    hidePaginationBar?: string;
    hideRefreshButton?: string;
    emptyStateView?: string;
  };
  order?: {
    enabled?: boolean;
  };
  views: any;
  showModalHeader?: boolean;
} & ElementBaseProps;

const VirtuosoTableComponents = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} sx={{ overflowY: "clip" }} />
  )),
  Table: props => {
    const { context, ...restProps } = props || {};
    return <Table {...restProps} sx={{ borderCollapse: "separate", tableLayout: "fixed", minWidth: "100%", width: "auto" }} />;
  },
  TableHead,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  TableRow: props => {
    const { children, ...restProps } = props;
    const { context } = restProps || {};
    const isDraggable = context?.order?.enabled;
    const rowItemId = props.item?.__id || props.item?.id;

    const rowProps = {
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        context?.handleSelectRow?.(props.item);
      },
      selected:
        (context?.selectedRows && context?.selectedRows.includes(rowItemId)) ||
        (context?.selectedRow && rowItemId === context?.selectedRow?.__id),
      hover: true,
      ...restProps,
    };

    const rowChildren = (
      <>
        {children}
        {children ? <TableCell sx={{ width: "auto" }} /> : <></>}
      </>
    );

    if (isDraggable) {
      return (
        <DraggableRow
          component={TableRow}
          componentProps={rowProps}
          id={props.item.id}
          index={props["data-index"]}
          name={`table-columns${context?.id}`}
          moveElement={context?.handleDragHover}
          updateElement={context?.handleUpdateElement}
        >
          {rowChildren}
        </DraggableRow>
      );
    } else {
      return <TableRow {...rowProps}>{rowChildren}</TableRow>;
    }
  },
};

export const BXDataTable: FC<DataTableProps> = props => {
  const {
    columns = [],
    auth,
    id = "",
    dataSource,
    actions,
    views,
    info,
    order,
    selectedViewId,
    __data = {},
    closeModal,
    parentIds = [],
    showModalHeader,
    config,
    pageId,
    path,
    parentRef,
    isCustomLayout,
  } = props;

  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState<any>(dataSource?.dataEntry);
  const [currentAction, setCurrentAction] = useState({});
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  let hasMore = useRef<any>(true);
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const containerRef = useRef<HTMLDivElement>(null);
  let gqlQuery;
  const isGraphQL = dataSource?.payload?.isGraphQL;
  if (isGraphQL) {
    gqlQuery = gql`
      ${dataSource?.payload?.graphqlQuery}
    `;
  }
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { viewsState, setViewsState, currentApp, getAuth, checkPermissions, queriesStateGraphQL } = useBXContext();

  const withInfiniteScroll = !info?.paginationMode || info?.paginationMode == "Infinite Scroll";
  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;
  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];
  const selectedRow = viewsState?.[queryKeys[0]]?._selectedItem;
  const userInputsValues = viewsState?.[queryKeys[0]]?.userInputs || {};

  if (cursors?.[currentPage - 1]) {
    queryKeys.push(cursors?.[currentPage - 1] as any);
  }
  // we need the key here to access the right data
  const { data, isFetching, hasNextPage, fetchNextPage, refetchDataSource } = useValue({
    queryKeys,
    __data,
    selectedItem: selectedRow,
    cursor: cursors?.[currentPage - 1],
    withInfiniteQuery: withInfiniteScroll,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    isApiCallInFirstLoad: dataSource?.isApiCallInFirstLoad,
    dataEntry: selectedDataEntry,
    pageId,
    endpoint: currentActionEndpoint,
    dataSource: { ...dataSource, payload: { ...dataSource?.payload, ...currentAction } },
    viewName: info?.viewName,
    isPagination: true,
  });

  // @ts-ignore
  // if (!withInfiniteScroll) {
  //   entries = (
  //     _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any))
  //   )?.filter?.(Boolean);
  // } else {
  //   entries = (
  //     _.isNil(selectedDataEntry) || !selectedDataEntry
  //       ? _.flatten(returnArrayValue(data?.pages))
  //       : _.flatten(data?.pages?.map((p: any) => returnArrayValue(_.get(p, selectedDataEntry as any))))
  //   )?.filter?.(Boolean);
  // }

  // Memoized entries calculation based on data and selected data entry
  const entries = useMemo(() => {
    if (withInfiniteScroll) {
      return _.flatMap(data, selectedDataEntry as string)?.map(item => ({ ...item, __id: uuid() })) || [];
    }

    const processedData =
      _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any));

    return processedData?.filter(Boolean)?.map(item => ({ ...item, __id: uuid() })) || [];
  }, [data, selectedDataEntry, withInfiniteScroll]);

  const loadMore = useCallback(async () => {
    if (hasNextPage && withInfiniteScroll && !isFetching) {
      await fetchNextPage?.();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  //TODO: Add cleanup for resources if any (Now in redux store)
  // useEffect(() => {
  //   return () => {
  //     if (dataSource?.sourceType == "USER INPUT") {
  //       handleCleanupQueries({
  //         isGraphQL,
  //         gqlQuery,
  //         queryKeys,
  //       });
  //     }
  //   };
  // }, []);

  const handleSelectRow = (itemData: any) => {
    setViewsState((prev: any) => {
      const data = { ...prev };
      data[`${pageId}-${info?.viewName}`] = {
        ...data[`${pageId}-${info?.viewName}`],
        _selectedItem: itemData,
      };
      return data;
    });
  };

  const handleDragHover = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];
    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = _.cloneDeep(data?.pages?.[pageIndex]);
    let item;

    if (selectedDataEntry) {
      const source = withInfiniteScroll ? page : data;
      item = _.get(source, selectedDataEntry)?.[dragIndex % pageSize];
    } else {
      item = withInfiniteScroll ? page?.[dragIndex % pageSize] : data?.[dragIndex];
    }

    let payload = {};

    let newPage = page;
    let newData: any = [];

    if (isGraphQL && withInfiniteScroll) {
      payload = { ..._.cloneDeep(data?.pages?.[0]) };

      if (selectedDataEntry) {
        _.set(
          payload,
          selectedDataEntry,
          update(_.get(_.cloneDeep(data?.pages?.[0]), selectedDataEntry as any), {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          })
        );
      }
    } else {
      if (withInfiniteScroll) {
        if (selectedDataEntry) {
          _.get(page, selectedDataEntry as any).splice(dragIndex % pageSize, 1);
        } else {
          page.splice(dragIndex % pageSize, 1);
        }
        if (selectedDataEntry) {
          _.set(newPage, selectedDataEntry, [
            ..._.get(page, selectedDataEntry as any).slice(0, hoverIndex % pageSize),
            item,
            ..._.get(page, selectedDataEntry as any).slice(hoverIndex % pageSize),
          ]);
        } else {
          newPage = [...page.slice(0, hoverIndex % pageSize), item, ...page.slice(hoverIndex % pageSize)];
        }
        newData = [...data.pages.slice(0, pageIndex), newPage, ...data.pages.slice(pageIndex + 1)];
      }

      payload = !withInfiniteScroll ? _.cloneDeep(data) : { ..._.cloneDeep(data), pages: newData };

      if (!withInfiniteScroll) {
        if (selectedDataEntry) {
          _.set(
            payload,
            selectedDataEntry,
            update(_.get(data, selectedDataEntry as any), {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, item],
              ],
            })
          );
        } else {
          payload = update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          });
        }
      }
    }

    const variables = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      cursor: cursors?.[currentPage - 1],
      fallback: null,
    });

    handleSetQueryData({
      isGraphQL,
      gqlQuery,
      payload,
      queryKeys: _queryKeys,
      variables,
    });
  };

  const handleUpdateElement = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];

    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = data?.pages?.[pageIndex];
    const dragItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex]
      : _.get(page, selectedDataEntry as any)?.[dragIndex % pageSize];

    const dropItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex - 1 === -1 ? 1 : dragIndex - 1]
      : _.get(page, selectedDataEntry as any)?.[(dragIndex - 1 === -1 ? 1 : dragIndex - 1) % pageSize];

    const url = replaceBaseUrl(config.order.endpoint, currentApp);
    const { token } = getAuth(currentApp?.id!) || {};

    axiosServices.request({
      url: replaceDataPlaceholders({
        queryString: url,
        item: dragItem,
        viewsState,
        pageId,
        __data,
        dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        env: currentApp?.env,
      }),
      data: config?.order?.isGraphQL
        ? {
            query: config?.order?.graphqlQuery,
            variables: JSON.stringify(
              replaceDataPlaceholdersRecursively({
                obj: JSON.parse(config?.order?.graphqlVariables || "{}"),
                viewsState,
                pageId,
                __data,
                env: currentApp?.env,
                dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
              })
            ),
          }
        : replaceDataPlaceholdersRecursively({
            obj: JSON.parse(config?.order?.body || "{}"),
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
          }),
      method: config?.order?.isGraphQL ? "post" : config?.order?.method,
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...replaceDataPlaceholdersRecursively({
          obj: config?.order?.headers,
          viewsState,
          pageId,
          __data,
          env: currentApp?.env,
          dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        }),
      },
    });
  };
  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);

  function handleSelectAllChange(event: any) {
    setIsSelectAllChecked(event.target.checked);
    if (!isSelectAllChecked) {
      if (data) {
        setSelectedRows(entries?.map((item: any) => item?.__id));
      }
    } else {
      setSelectedRows([]);
    }
  }

  useEffect(() => {
    if (isSelectAllChecked) {
      if (entries) {
        setSelectedRows(entries?.map((item: any) => item?.__id));
      }
    }
  }, [data]);

  useEffect(() => {
    if (cursors?.length === 1 && data !== null) {
      setCursors(prev => {
        const populatedCursors = [...prev, data?.cursor];
        return populatedCursors;
      });
    }
  }, [data]);

  const isHeaderVisible =
    (_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal))) ||
    selectedRows.length > 0 ||
    (!withInfiniteScroll && !!entries?.length) ||
    (info?.showApiMode !== "Hidden" && !showModalHeader) ||
    dataSource?.sourceType == "USER INPUT";

  const renderEmptyStateView = useCallback(() => {
    const _views = views?.concat(getSharedViews(currentApp));
    const targetView = _views?.find(v => v.id === info?.emptyStateView);

    return (
      <BXEngine
        auth={{}}
        pageId={pageId}
        page={{ views: views, layout: targetView, id: pageId } as any}
        path={[path, targetView?.info?.name]?.join(".")}
        layout={[{ ...(targetView as any), type: targetView?.type }]}
        __data={__data}
      />
    );
  }, [info?.emptyStateView, views]);

  const rowRenderer = (type, data, index) => {
    return (
      <TableItem
        key={data.__id}
        path={path}
        auth={auth}
        viewName={info?.viewName}
        pageId={pageId}
        views={views}
        queryKeys={queryKeys}
        columns={columns}
        actions={actions}
        userInputsValues={userInputsValues}
        isUserInput={dataSource?.sourceType == "USER INPUT"}
        item={data}
        onSelectRow={handleSelectRow}
        tableId={id}
        __data={__data}
        closeModal={closeModal}
        parentIds={parentIds}
        info={info}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isSelectAllChecked={isSelectAllChecked}
      />
    );
  };

  function getWidth(col: BXDataTableColumn) {
    if (!col?.customWidth) {
      return col?.type == "Video" || col?.type == "Audio" ? "360px" : "auto";
    }
    return `${col?.customWidth}px`;
  }

  //Respond to specific events and signals related to the current view or component and consume them
  const viewKey: string = `${pageId}.${info?.viewName}`;
  const trigger = useAppTrigger(viewKey);

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        await refetchDataSource?.();
        trigger?.payload?.resolver?.();
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        await fetchNextPage?.(cursor, true);

        trigger?.payload?.resolver?.();
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  const state = store.getState();
  const targetKey = `${pageId}.${info?.viewName}`;
  const paginationInfoForElementData = _.get(state?.appState?.pagination, targetKey);
  const nextCursor = paginationInfoForElementData?.data?.nextCursor;

  useEffect(() => {
    setCursors(prevCursors => {
      let updatedCursors;

      if (!prevCursors.includes(nextCursor)) {
        updatedCursors = [...prevCursors, nextCursor];
      } else {
        const index = prevCursors.indexOf(nextCursor);
        updatedCursors = prevCursors.slice(0, index + 1);
      }

      const lengthViewData = _.get(store.getState().appState.pagination, targetKey)?.data?.length;

      hasMore.current = paginationInfoForElementData?.data?.hasMore;
      if (!hasMore.current && lengthViewData == 0) {
        setTotalPageCount(updatedCursors.length - 2);
        setCurrentPage(updatedCursors.length - 2);
      } else {
        setTotalPageCount(updatedCursors.length - 1);
        setCurrentPage(updatedCursors.length - 1);
      }
      return updatedCursors;
    });
  }, [nextCursor]);

  function fixedHeaderContent() {
    return (
      <>
        {!isApiMode && (
          <TableRow>
            {info?.enableMultipleSelection && (
              <TableCell style={{ background: palette.background.default, paddingInlineStart: "8px" }}>
                <Checkbox color='primary' onChange={handleSelectAllChange} />
                Rows
              </TableCell>
            )}
            {columns.map(column => (
              <PermissibleRender key={column.name} isAllowed action={["VIEW"]} path={[path, column.name].join(".")}>
                {({ permitted }) => {
                  if (!permitted) return null;

                  const builderView = views?.find((view: any) => view?.id === column?.viewBuilderHeaderId);

                  const renderViewBuilder = () => (
                    <Box
                      sx={{
                        maxHeight: column?.customHeight ? `${column?.customHeight}px` : "41px",
                        ...(column?.customHeight ? { minHeight: `${column?.customHeight}px` } : {}),
                        overflow: "hidden",
                        height: "100%",
                      }}
                    >
                      <BXEngine
                        auth={{}}
                        path={[path, builderView?.name].join(".")}
                        pageId={pageId}
                        page={{ views, layout: builderView, id: pageId } as any}
                        layout={[{ ...(builderView as any), type: builderView?.type }]}
                        data={column}
                        __data={__data}
                        insideCell
                      />
                    </Box>
                  );

                  return (
                    <TableCell
                      style={{
                        background: palette.background.default,
                        minWidth: getWidth(column),
                      }}
                      align={(column?.alignColumn as any) ?? "left"}
                      key={column.name}
                      sx={{ paddingInlineStart: 3 }}
                    >
                      {column?.headerCellType === "viewBuilder" && builderView ? renderViewBuilder() : column.name}
                    </TableCell>
                  );
                }}
              </PermissibleRender>
            ))}
            <TableCell sx={{ width: "auto" }} />
          </TableRow>
        )}
      </>
    );
  }

  if (info.emptyStateView && _.isEmpty(entries) && !isFetching) {
    return renderEmptyStateView();
  }

  return (
    <Box marginY={1}>
      <Card variant={"outlined"}>
        {selectedRows.length > 0 && (
          <div>
            <Grid
              style={{ backgroundColor: palette?.primary?.light, minHeight: "60px", display: "flex", alignItems: "center" }}
              container
              paddingX={2}
            >
              <Grid container item xs={12} justifyContent='flex-end'>
                <>
                  <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                    {selectedRows?.length} selected
                  </Typography>
                </>

                {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.enableSelectedRows)) && (
                  <>
                    {actions
                      ?.filter(action => action.enableSelectedRows)
                      .map(action => (
                        <PermissibleRender isAllowed key={action.id} action={["VIEW"]} path={[path, action.label].join(".")}>
                          {({ permitted }) => (
                            <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                              <>
                                <ActionButton
                                  path={[path, action.label].join(".")}
                                  queryKeys={queryKeys}
                                  viewName={info?.viewName}
                                  pageId={pageId}
                                  item={{}}
                                  tableId={id}
                                  views={views}
                                  __data={__data}
                                  closeModal={closeModal}
                                  iconButton={!action?.showButtonLabel}
                                  parentIds={parentIds}
                                  entries={entries}
                                  selectedRows={selectedRows}
                                  disabled={!permitted}
                                  tableAction={{
                                    action: action,
                                    label: action?.label,
                                    icon:
                                      (action?.iconConfig?.visibility === "PUBLIC" &&
                                      action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                        ? action?.iconConfig?.url
                                        : action?.iconConfig?.icon) || action?.icon,
                                    condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                                  }}
                                  isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                                  actionsMap={action?.actionsMap}
                                  actionsKey={action?.actionsKey}
                                  isChainMapped={action?.isChainMapped}
                                  conditionKeyFromTable={action?.conditionActionMapKey}
                                />
                              </>
                            </Box>
                          )}
                        </PermissibleRender>
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {selectedRows.length == 0 && (
          <Grid
            style={{ minHeight: !isHeaderVisible ? 0 : "60px", alignItems: "center" }}
            container
            paddingX={isCustomLayout ? 0 : 2}
            mt={2}
          >
            <Grid container item xs={12} justifyContent='flex-end'>
              {!showModalHeader && !info?.hideViewName && (
                <>
                  <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                    {replaceDataPlaceholders({
                      queryString: info?.name,
                      viewsState,
                      pageId,
                      env: currentApp?.env,
                      __data,
                    })}
                  </Typography>
                </>
              )}
              {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal && !action?.enableSelectedRows)) && (
                <>
                  {actions
                    ?.filter(action => action.isGlobal)
                    .map(action => (
                      <PermissibleRender isAllowed action={["VIEW"]} path={[path, action.label].join(".")}>
                        {({ permitted }) => (
                          <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                            <>
                              <ActionButton
                                path={[path, action.label].join(".")}
                                queryKeys={queryKeys}
                                viewName={info?.viewName}
                                pageId={pageId}
                                item={{}}
                                tableId={id}
                                tableAction={{
                                  action: action,
                                  label: action?.label,
                                  icon:
                                    (action?.iconConfig?.visibility === "PUBLIC" &&
                                    action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                      ? action?.iconConfig?.url
                                      : action?.iconConfig?.icon) || action?.icon,
                                  condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                                }}
                                views={views}
                                __data={__data}
                                closeModal={closeModal}
                                iconButton={!action?.showButtonLabel}
                                parentIds={parentIds}
                                entries={entries}
                                disabled={!permitted}
                                isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                                actionsMap={action?.actionsMap}
                                actionsKey={action?.actionsKey}
                                isChainMapped={action?.isChainMapped}
                                conditionKeyFromTable={action?.conditionActionMapKey}
                              />
                            </>
                          </Box>
                        )}
                      </PermissibleRender>
                    ))}
                </>
              )}
              {selectedRows.length > 0 && (
                <Tooltip title='Delete'>
                  <Box marginInlineStart={1} alignSelf='center'>
                    <IconButton style={{ backgroundColor: palette.primary.light }}>
                      <DeleteIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
              {!showModalHeader && !info?.hideRefreshButton && (
                <Tooltip title='Refresh'>
                  <Box marginInlineStart={1} alignSelf='center'>
                    <IconButton
                      onClick={() => {
                        setIsRefreshing(true);
                        const key = `${pageId}.${info?.viewName}`;
                        handleRefetchQueries({
                          isGraphQL,
                          queriesStateGraphQL,
                          key,
                          setIsRefreshing,
                          dispatch,
                        });
                      }}
                      disabled={isFetching}
                      style={{ backgroundColor: palette.primary.light }}
                    >
                      {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                    </IconButton>
                  </Box>
                </Tooltip>
              )}

              {!withInfiniteScroll && !!entries?.length && !info.hidePaginationBar && (
                <>
                  <Pagination
                    currentPage={currentPage}
                    totalPageCount={totalPageCount}
                    // hasNextPage={_.get(data, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") && !isFetching}
                    hasNextPage={currentPage == 1 ? _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") : hasMore.current}
                    isFetching={isFetching}
                    onPageChange={async (page: number) => {
                      const targetKey = `${pageId}.${info?.viewName}`;

                      let currentCursor = cursors?.[page - 1];

                      await fetchNextPage?.(currentCursor, true);
                      hasMore.current = _.get(store.getState().appState.pagination, targetKey)?.data?.hasMore;
                      const lengthViewData = _.get(store.getState().appState.pagination, targetKey)?.data?.length;

                      if (!hasMore.current && lengthViewData == 0) {
                        setCurrentPage(page - 1);
                      } else {
                        setCurrentPage(page);
                      }
                    }}
                  />
                </>
              )}
              {info?.showApiMode !== "Hidden" && !showModalHeader && (
                <Box
                  display='flex'
                  alignItems='center'
                  borderColor='palette.text.primary'
                  marginInlineStart={2}
                  paddingX={1.5}
                  borderRadius={10}
                  border={1}
                >
                  <Typography>API Mode</Typography>
                  <Switch onChange={(_, value) => setIsApiMode(value)} />
                </Box>
              )}
            </Grid>

            {dataSource?.sourceType == "USER INPUT" && (
              <Box width='100%'>
                {/* <UserInput
                  queryKeys={queryKeys}
                  pageId={pageId}
                  setCurrentActionEndpoint={setCurrentActionEndpoint}
                  userInputView={userInputView}
                  __data={__data}
                  refetch={refetch}
                  views={views}
                  setDataEntry={setDataEntry}
                  setCurrentAction={setCurrentAction}
                  viewName={info?.viewName}
                  hideViewName={info?.hideViewName}
                /> */}
              </Box>
            )}
          </Grid>
        )}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer ref={containerRef}>
            {isApiMode ? (
              <ReactJson src={entries} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <>
                {!_.isEmpty(entries) ? (
                  <>
                    <TableVirtuoso
                      context={{ id, order, selectedRows, selectedRow, handleSelectRow, handleDragHover, handleUpdateElement }}
                      data={entries}
                      totalCount={entries?.length || 0}
                      endReached={() => {
                        loadMore?.();
                      }}
                      computeItemKey={(_index, item) => item.id}
                      increaseViewportBy={200}
                      fixedHeaderContent={fixedHeaderContent}
                      itemContent={rowRenderer}
                      components={VirtuosoTableComponents}
                      useWindowScroll
                      customScrollParent={parentRef?.current}
                    />
                    {isFetching && (
                      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <CircularProgress />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Table>
                    <TableHead>{fixedHeaderContent()}</TableHead>
                    <TableRow>
                      <TableCell colSpan={(columns.length || 0) + 1}>
                        {isFetching ? (
                          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            <CircularProgress />
                          </Grid>
                        ) : (
                          <Typography component='div' align='center'>
                            {dataSource?.sourceType !== "TABLE"
                              ? "No Records Available"
                              : `Select Data from ${dataSource?.table?.info?.name} Table`}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
              </>
            )}
          </TableContainer>
        </Paper>
      </Card>
    </Box>
  );
};
