// PaginationBar.tsx
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "src/components/pagination/Pagination";
import { produceTrigger } from "src/features/appState/appStateSlice";
import { useAppTrigger } from "src/features/appState/selectors";
import store, { RootState } from "src/store/store";
import { PaginationBarProps } from "./types";

export const PaginationBar: FC<PaginationBarProps> = props => {
  const {
    siblingCount = 1,
    isFetching = false,
    className,
    unselectedPageBackgroundColor,
    unselectedPageColor,
    unselectedPageColorHover,
    selectedPageBackgroundColor,
    selectedPageColor,
    selectedPageColorHover,
    fwdCursor,
    prevCursor,
    lastCursor,
    onChange,
    config,
    metaData,
    maxPrev,
    pageType,
    pagesOrientation,
    ...restProps
  } = props;

  const pageId = metaData?.pageId || "";
  const viewName = metaData?.viewName || "";
  const componentKey = restProps["data-bx-key"];
  const dispatch = useDispatch();

  let elementKey = "";
  if (config?.dataPagination.startsWith("{this")) {
    if (config?.dataPagination === "{this}") {
      //Current View
      elementKey = `${pageId}.${viewName}`;
    } else {
      //Component in the current view
      const placeholder = config?.dataPagination.slice("{this.".length, -1);
      elementKey = `${pageId}.${viewName}.${placeholder}`;
    }
  } else {
    //Another view in the same page
    config?.dataPagination.replace(/{(\w+)(\.\w+)?}/g, (match, view, placeholder) => {
      elementKey = placeholder ? `${pageId}.${view}${placeholder}` : `${pageId}.${view}`;
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const ref = useRef(false);
  const paginationInfoForElement = useSelector((state: RootState) => _.get(state.appState.pagination, elementKey));
  let hasMore = useRef(false);

  useEffect(() => {
    const state = store.getState();
    const paginationInfoForElementData = _.get(state?.appState?.pagination, elementKey);
    const paginationInfoForElement = paginationInfoForElementData?.data;

    if (paginationInfoForElement && !ref.current) {
      setCursors(prev => [...prev, paginationInfoForElement?.nextCursor]);
      hasMore.current = paginationInfoForElement?.hasMore;
      ref.current = true;
    }
  }, [paginationInfoForElement]);

  const trigger = useAppTrigger(componentKey);

  useEffect(() => {
    const handleTrigger = async () => {
      if (trigger?.type === "updatePage") {
        await onPageChange(trigger?.payload?.page);
        trigger?.payload?.resolver?.();
      }
    };

    handleTrigger();
  }, [trigger]);

  const onPageChange = async (page: number) => {
    await new Promise((resolve, reject) => {
      dispatch(
        produceTrigger({
          name: elementKey,
          type: "fetchNextPage",
          eventPayload: {
            resolver: resolve,
            currentCursorParam: cursors[page - 1],
            prevCursorParam: currentPage === 2 ? "undefined" : cursors[currentPage - 2],
          },
        })
      );
    });
    hasMore.current = _.get(store.getState().appState.pagination, elementKey)?.data?.hasMore;

    const lengthViewData = _.get(store.getState().appState.pagination, elementKey)?.data?.length;

    if (!hasMore.current && lengthViewData == 0) {
      setCurrentPage(page - 1);
      setTotalPageCount(page - 1);
    } else {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    const updatedValues = {
      currentPage: currentPage,
      currentCursor: cursors[currentPage - 1],
      fwdCursor: cursors[currentPage],
      prevCursor: currentPage === 2 ? "undefined" : cursors[currentPage - 1],
      firstCursor: "undefined",
      hasMore: hasMore.current,
    };
    onChange?.(updatedValues);
  }, [cursors, currentPage]);

  const state = store.getState();
  const paginationInfoForElementData = _.get(state?.appState?.pagination, elementKey);
  const nextCursor = paginationInfoForElementData?.data?.nextCursor;

  useEffect(() => {
    setCursors(prevCursors => {
      let updatedCursors;
      if (!prevCursors.includes(nextCursor)) {
        updatedCursors = [...prevCursors, nextCursor];
      } else {
        const index = prevCursors.indexOf(nextCursor);
        updatedCursors = prevCursors.slice(0, index + 1);
      }
      hasMore.current = paginationInfoForElementData?.data?.hasMore;

      const lengthViewData = _.get(store.getState().appState.pagination, elementKey)?.data?.length;
      if (!hasMore.current && lengthViewData == 0) {
        setTotalPageCount(updatedCursors.length - 2);
        setCurrentPage(updatedCursors.length - 2);
      } else {
        setTotalPageCount(updatedCursors.length - 1);
        setCurrentPage(updatedCursors.length - 1);
      }

      return updatedCursors;
    });
  }, [nextCursor]);

  return (
    <Pagination
      currentPage={currentPage > 0 ? currentPage : 1 || 1}
      totalPageCount={totalPageCount > 0 ? totalPageCount : 1 || 1}
      onPageChange={onPageChange}
      siblingCount={siblingCount}
      hasNextPage={hasMore.current}
      showPrevButton={false}
      showNextButton={false}
      unselectedPageBackgroundColor={unselectedPageBackgroundColor}
      unselectedPageColor={unselectedPageColor}
      unselectedPageColorHover={unselectedPageColorHover}
      selectedPageBackgroundColor={selectedPageBackgroundColor}
      selectedPageColor={selectedPageColor}
      selectedPageColorHover={selectedPageColorHover}
      maxPrev={maxPrev}
      pageType={pageType}
      pagesOrientation={pagesOrientation}
      isPaginationBar={true}
    />
  );
};
