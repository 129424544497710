export enum ComponentItemType {
  CustomContainer = "CustomContainer",
  // CustomAccordion = "CustomAccordion",
  TextField = "TextField",
  BXSelect = "Select",
  Button = "Button",
  CustomIconButton = "CustomIconButton",
  CustomChip = "CustomChip",
  Avatar = "Avatar",
  Typography = "Typography",
  Divider = "Divider",
  CustomSwitch = "CustomSwitch",
  CustomCheckbox = "CustomCheckbox",
  CustomAutoCompleteBX = "CustomAutoCompleteBX",
  CustomImage = "CustomImage",
  FlexContainer = "FlexContainer",
  CustomRadio = "CustomRadio",
  CustomMediaCard = "CustomMediaCard",
  CustomGoogleMap = "CustomGoogleMap",
  GoogleMapAutocomplete = "GoogleMapAutocomplete",
  FileUploadInput = "FileUploadInput",
  GridContainer = "GridContainer",
  DatePicker = "DatePicker",
  DateTimePicker = "DateTimePicker",
  TimePicker = "TimePicker",
  CustomTabs = "CustomTabs",
  SplineChart = "SplineChart",
  PieChart = "PieChart",
  ColumnChart = "ColumnChart",
  CircularChart = "CircularChart",
  JsonViewer = "JsonViewer",
  StepperNavigator = "StepperNavigator",
  StepperContainer = "StepperContainer",
  MarkdownViewer = "MarkdownViewer",
  ColorPicker = "ColorPicker",
  CustomQR = "CustomQR",
  PaginationBar = "PaginationBar",
  BXView = "BXView",
  BXLayout = "BXLayout",
  BXSideBar = "BXSideBar",
  BXNavBar = "BXNavBar",
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export enum StepperComponentState {
  NotFound = "NotFound",
  FoundButNotSelected = "FoundButNotSelected",
  FoundAndSelected = "FoundAndSelected",
}
