import { gql } from "@apollo/client";
import { Checkbox, Paper, Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconRefresh } from "@tabler/icons-react";
import update from "immutability-helper";
import _, { PropertyPath } from "lodash";
import { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
import { VirtuosoGrid } from "react-virtuoso";
import Sound1 from "src/assets/sounds/notification-sound-1.mp3";
import Sound2 from "src/assets/sounds/notification-sound-2.mp3";
import Sound3 from "src/assets/sounds/notification-sound-3.mp3";
import { BXEngine } from "src/BXEngine";
import { Pagination } from "src/components/pagination";
import { useAppTrigger } from "src/features/appState/selectors";
import store from "src/store/store";
import { ElementBaseProps } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import {
  getAuthorizationHeader,
  handleCleanupQueries,
  handleRefetchQueries,
  handleSetQueryData,
  returnArrayValue,
} from "src/utils/generalUtils";
import { getSharedViews } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { replaceBaseUrl, useBXContext, useValue } from "../../../BXEngine/BXContext";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { UserInput } from "../UserInput";
import BXRepeatedViewItem from "./BXRepeatedViewItem";

const components = {
  List: forwardRef<HTMLDivElement, { children?: React.ReactNode; style?: React.CSSProperties }>(({ children, ...props }, ref) => (
    <Grid ref={ref} container item xs={12} spacing={1} {...props}>
      {children}
    </Grid>
  )),
  Item: props => {
    return <>{props?.children}</>;
  },
};

export const BXRepeatedView: FC<ElementBaseProps> = ({
  id,
  dataSource,
  info,
  actions,
  views,
  selectedViewId,
  __data = {},
  closeModal,
  type,
  parentIds = [],
  index,
  pageId,
  config,
  path,
  showModalHeader,
  parentRef,
}) => {
  const { viewsState, setViewsState, currentApp, getAuth, queriesStateGraphQL } = useBXContext();
  const dispatch = useDispatch();
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const [currentAction, setCurrentAction] = useState(dataSource?.payload);
  const [isApiMode, setIsApiMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState(dataSource?.dataEntry);
  const { palette } = useTheme();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedViewIndex, setSelectedViewIndex] = useState<number | undefined>(undefined);
  let hasMore = useRef<any>(true);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;
  let gqlQuery;
  const isGraphQL = dataSource?.payload?.isGraphQL;
  if (isGraphQL) {
    gqlQuery = gql`
      ${dataSource?.payload?.graphqlQuery}
    `;
  }

  const soundRef1 = useRef(null);
  const soundRef2 = useRef(null);
  const soundRef3 = useRef(null);

  const soundMap: any = {
    sound1: soundRef1,
    sound2: soundRef2,
    sound3: soundRef3,
  };

  const withInfiniteScroll = (!info?.paginationMode || info?.paginationMode == "Infinite Scroll") && !dataSource?.splitViewConfig?.enabled;

  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];

  const selectedRow = viewsState?.[queryKeys[0]]?._selectedItem;
  const userInputsValues = viewsState?.[queryKeys[0]]?.userInputs || {};

  if (cursors?.[currentPage - 1]) {
    queryKeys.push(cursors?.[currentPage - 1] as any);
  }

  const { data, isFetching, hasNextPage, fetchNextPage, refetchDataSource, isError } = useValue({
    queryKeys,
    __data,
    selectedItem: selectedRow,
    cursor: cursors?.[currentPage - 1],
    withInfiniteQuery: withInfiniteScroll,
    viewId: id,
    limit: dataSource?.splitViewConfig?.enabled ? 1 : dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    isApiCallInFirstLoad: dataSource?.isApiCallInFirstLoad,
    dataEntry: selectedDataEntry,
    pageId,
    notificationMessage: info?.notificationMessage,
    soundPlayer: soundMap[info?.notificationSound as any],
    pagination: { index },
    endpoint: currentActionEndpoint,
    options: {
      refetchInterval: info?.autoRefresh && (info?.timer || 60) * 1000,
      refetchIntervalInBackground: !!info?.autoRefresh,
      onSuccess: (data: any) => {
        if (dataSource?.splitViewConfig?.enabled && _.get(data, selectedDataEntry as any)?.[0]) {
          handleSelectRow(_.get(data, selectedDataEntry as any)?.[0]);
        }
      },
    },
    dataSource: { ...dataSource, payload: currentAction },
    viewName: info?.viewName,
    isPagination: true,
  });

  // Memoized entries calculation based on data and selected data entry
  const entries = useMemo(() => {
    if (withInfiniteScroll) {
      return _.flatMap(data, selectedDataEntry as string)?.map(item => ({ ...item, __id: uuid() })) || [];
    }

    const processedData =
      _.isNil(selectedDataEntry) || !selectedDataEntry ? returnArrayValue(data) : returnArrayValue(_.get(data, selectedDataEntry as any));

    return processedData?.filter(Boolean)?.map(item => ({ ...item, __id: uuid() })) || [];
  }, [data, selectedDataEntry, withInfiniteScroll]);

  const loadMore = useCallback(async () => {
    if (hasNextPage && withInfiniteScroll && !isFetching) {
      await fetchNextPage?.();
    }
  }, [fetchNextPage, hasNextPage, isFetching]);

  useEffect(() => {
    return () => {
      if (dataSource?.sourceType == "USER INPUT") {
        handleCleanupQueries({
          isGraphQL,
          gqlQuery,
          queryKeys: [id],
        });
      }
    };
  }, []);

  useEffect(() => {
    if (cursors?.length === 1 && data !== null) {
      setCursors(prev => {
        const populatedCursors = [...prev, data?.cursor];
        return populatedCursors;
      });
    }
  }, [data]);
  const state = store.getState();
  const targetKey = `${pageId}.${info?.viewName}`;
  const paginationInfoForElementData = _.get(state?.appState?.pagination, targetKey);
  const nextCursor = paginationInfoForElementData?.data?.nextCursor;

  useEffect(() => {
    setCursors(prevCursors => {
      let updatedCursors;

      if (!prevCursors.includes(nextCursor)) {
        updatedCursors = [...prevCursors, nextCursor];
      } else {
        const index = prevCursors.indexOf(nextCursor);
        updatedCursors = prevCursors.slice(0, index + 1);
      }

      const lengthViewData = _.get(store.getState().appState.pagination, targetKey)?.data?.length;

      hasMore.current = paginationInfoForElementData?.data?.hasMore;
      if (!hasMore.current && lengthViewData == 0) {
        setTotalPageCount(updatedCursors.length - 2);
        setCurrentPage(updatedCursors.length - 2);
      } else {
        setTotalPageCount(updatedCursors.length - 1);
        setCurrentPage(updatedCursors.length - 1);
      }
      return updatedCursors;
    });
  }, [nextCursor]);

  const handleSelectRow = (itemData: any, index?: number) => {
    setViewsState((prev: any) => {
      const data = { ...prev };
      data[`${pageId}-${info?.viewName}`] =
        index === selectedViewIndex
          ? { ...data[`${pageId}-${info?.viewName}`] }
          : {
              ...data[`${pageId}-${info?.viewName}`],
              _selectedItem: itemData,
            };
      setSelectedViewIndex(prev => (index === prev ? undefined : index));
      return data;
    });
  };

  const handleDragHover = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];
    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = _.cloneDeep(data?.pages?.[pageIndex]);
    let item;

    if (selectedDataEntry) {
      const source = withInfiniteScroll ? page : data;
      item = _.get(source, selectedDataEntry)?.[dragIndex % pageSize];
    } else {
      item = withInfiniteScroll ? page?.[dragIndex % pageSize] : data?.[dragIndex];
    }

    let payload = {};

    let newPage = page;
    let newData: any = [];

    if (isGraphQL && withInfiniteScroll) {
      payload = { ..._.cloneDeep(data?.pages?.[0]) };

      if (selectedDataEntry) {
        _.set(
          payload,
          selectedDataEntry,
          update(_.get(_.cloneDeep(data?.pages?.[0]), selectedDataEntry as any), {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          })
        );
      }
    } else {
      if (withInfiniteScroll) {
        if (selectedDataEntry) {
          _.get(page, selectedDataEntry as any).splice(dragIndex % pageSize, 1);
        } else {
          page.splice(dragIndex % pageSize, 1);
        }
        if (selectedDataEntry) {
          _.set(newPage, selectedDataEntry, [
            ..._.get(page, selectedDataEntry as any).slice(0, hoverIndex % pageSize),
            item,
            ..._.get(page, selectedDataEntry as any).slice(hoverIndex % pageSize),
          ]);
        } else {
          newPage = [...page.slice(0, hoverIndex % pageSize), item, ...page.slice(hoverIndex % pageSize)];
        }
        newData = [...data.pages.slice(0, pageIndex), newPage, ...data.pages.slice(pageIndex + 1)];
      }

      payload = !withInfiniteScroll ? _.cloneDeep(data) : { ...data, pages: newData };

      if (!withInfiniteScroll) {
        if (selectedDataEntry) {
          _.set(
            payload,
            selectedDataEntry,
            update(_.get(data, selectedDataEntry as any), {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, item],
              ],
            })
          );
        } else {
          payload = update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, item],
            ],
          });
        }
      }
    }
    const variables = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      cursor: cursors?.[currentPage - 1],
    });

    handleSetQueryData({
      isGraphQL,
      gqlQuery,
      payload,
      queryKeys: _queryKeys,
      variables,
    });
  };

  const handleUpdateElement = (dragIndex: any, hoverIndex: number) => {
    const _queryKeys = [...queryKeys];

    const cursor = cursors?.[currentPage - 1];

    if (cursor) {
      _queryKeys.push(cursor);
    }

    const pageIndex = data?.pages?.findIndex(
      (items: any) => _.get(items, selectedDataEntry as any)?.findIndex((item: any) => entries.indexOf(item) === dragIndex) !== -1
    );

    const pageSize = 20;
    const page = data?.pages?.[pageIndex];
    const dragItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex]
      : _.get(page, selectedDataEntry as any)?.[dragIndex % pageSize];

    const dropItem = !withInfiniteScroll
      ? _.get(data, selectedDataEntry as any)?.[dragIndex - 1 === -1 ? 1 : dragIndex - 1]
      : _.get(page, selectedDataEntry as any)?.[(dragIndex - 1 === -1 ? 1 : dragIndex - 1) % pageSize];

    const url = replaceBaseUrl(config.order.endpoint, currentApp);
    const { token } = getAuth(currentApp?.id!) || {};

    axiosServices.request({
      url: replaceDataPlaceholders({
        queryString: url,
        item: dragItem,
        viewsState,
        pageId,
        __data,
        dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        env: currentApp?.env,
      }),
      data: config?.order?.isGraphQL
        ? {
            query: config?.order?.graphqlQuery,
            variables: JSON.stringify(
              replaceDataPlaceholdersRecursively({
                obj: JSON.parse(config?.order?.graphqlVariables || "{}"),
                viewsState,
                pageId,
                __data,
                env: currentApp?.env,
                dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
              })
            ),
          }
        : replaceDataPlaceholdersRecursively({
            obj: JSON.parse(config?.order?.body || "{}"),
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
          }),
      method: config?.order?.isGraphQL ? "post" : config?.order?.method,
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...replaceDataPlaceholdersRecursively({
          obj: config?.order?.headers,
          viewsState,
          pageId,
          __data,
          env: currentApp?.env,
          dnd: { drag: dragItem, drop: dropItem, direction: dragIndex === 0 ? "BEFORE" : "AFTER" },
        }),
      },
    });
  };

  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);
  const isFixedHeight = !info?.isPercentageHeight;

  function handleSelectAllChange(event: any) {
    setIsSelectAllChecked(event.target.checked);
    if (!isSelectAllChecked) {
      if (data) {
        setSelectedRows(entries?.map((item: any) => item?.__id));
      }
    } else {
      setSelectedRows([]);
    }
  }

  useEffect(() => {
    if (isSelectAllChecked) {
      if (entries) {
        setSelectedRows(entries?.map((item: any) => item?.__id));
      }
    }
  }, [data]);

  //Respond to specific events and signals related to the current view or component and consume them
  const viewKey: string = `${pageId}.${info?.viewName}`;
  const trigger = useAppTrigger(viewKey);

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        refetchDataSource?.();
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        await fetchNextPage?.(cursor, true);

        trigger?.payload?.resolver?.();
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  const renderEmptyStateView = useCallback(() => {
    if (!info?.emptyStateView) {
      return <div>No Records Available</div>;
    }

    const _views = views?.concat(getSharedViews(currentApp));
    const targetView = _views?.find(v => v.id === info?.emptyStateView);
    return (
      <BXEngine
        auth={{}}
        pageId={pageId}
        page={{ views: views, layout: targetView, id: pageId } as any}
        path={[path, targetView?.info?.name]?.join(".")}
        layout={[{ ...(targetView as any), type: targetView?.type }]}
        __data={__data}
      />
    );
  }, [info?.emptyStateView, views]);

  if (_.isEmpty(entries) && !isFetching) {
    return renderEmptyStateView();
  }

  return (
    <Box paddingX={1}>
      <audio ref={soundRef1} src={Sound1} />
      <audio ref={soundRef2} src={Sound2} />
      <audio ref={soundRef3} src={Sound3} />
      <Card variant={"outlined"}>
        {selectedRows.length > 0 && (
          <div>
            <Grid
              style={{ backgroundColor: palette?.primary?.light, minHeight: "60px", display: "flex", alignItems: "center" }}
              container
              paddingX={2}
            >
              <Grid container item xs={12} justifyContent='flex-end'>
                <>
                  <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                    {selectedRows?.length} selected
                  </Typography>
                </>

                <>
                  {actions
                    ?.filter(action => action.enableSelectedRows)
                    .map(action => (
                      <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                        <ActionButton
                          item={{}}
                          path={[path, action.label].join(".")}
                          tableId={id}
                          queryKeys={queryKeys}
                          pageId={pageId}
                          viewName={info?.viewName}
                          tableAction={{
                            action: action,
                            label: action?.label,
                            icon:
                              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                ? action?.iconConfig?.url
                                : action?.iconConfig?.icon) || action?.icon,
                            condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                          }}
                          views={views}
                          closeModal={closeModal}
                          __data={__data}
                          parentIds={parentIds}
                          iconButton={!action?.showButtonLabel}
                          entries={entries}
                        />
                      </Box>
                    ))}
                </>
              </Grid>
            </Grid>
          </div>
        )}
        <Grid container paddingX={2} mt={2} mb={2}>
          {selectedRows.length == 0 && (
            <Grid style={{ minHeight: "60px", alignItems: "center" }} container xs={12}>
              <Grid container item xs={12} justifyContent='flex-end'>
                {!showModalHeader && !info?.hideViewName && (
                  <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                    {replaceDataPlaceholders({
                      queryString: info?.name,
                      item: selectedRow,
                      viewsState,
                      pageId,
                      __data,
                      pagination: { index },
                      env: currentApp?.env,
                    })}
                  </Typography>
                )}

                {_.isArray(actions) && !_.isEmpty(actions.filter(action => action.isGlobal && !action.enableSelectedRows)) && (
                  <>
                    {actions
                      ?.filter(action => action.isGlobal)
                      .map(action => (
                        <Box key={action.id} marginInlineStart={1} alignSelf='center'>
                          <ActionButton
                            item={{}}
                            path={[path, action.name].join(".")}
                            tableId={id}
                            queryKeys={queryKeys}
                            pageId={pageId}
                            viewName={info?.viewName}
                            tableAction={{
                              action: action,
                              label: action?.label,
                              icon:
                                (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                  ? action?.iconConfig?.url
                                  : action?.iconConfig?.icon) || action?.icon,
                              condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                            }}
                            views={views}
                            closeModal={closeModal}
                            __data={__data}
                            parentIds={parentIds}
                            iconButton={!action?.showButtonLabel}
                            entries={entries}
                          />
                        </Box>
                      ))}
                  </>
                )}
                <Tooltip title='Refresh'>
                  <Box marginInlineStart={1} alignSelf='center'>
                    <IconButton
                      onClick={() => {
                        setIsRefreshing(true);
                        const key = `${pageId}-${info?.viewName}`;
                        handleRefetchQueries({
                          isGraphQL,
                          queriesStateGraphQL,
                          key,
                          queryKeys,
                          setIsRefreshing,
                          dispatch,
                        });
                      }}
                      disabled={isFetching}
                      style={{ backgroundColor: palette.primary.light }}
                    >
                      {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                    </IconButton>
                  </Box>
                </Tooltip>
                {!withInfiniteScroll && !dataSource?.splitViewConfig?.enabled && !!entries?.length && (
                  <Pagination
                    currentPage={currentPage}
                    totalPageCount={totalPageCount}
                    hasNextPage={currentPage == 1 ? _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "hasMore") : hasMore.current}
                    isFetching={isFetching}
                    onPageChange={async (page: number) => {
                      const targetKey = `${pageId}.${info?.viewName}`;

                      let currentCursor = cursors?.[page - 1];

                      await fetchNextPage?.(currentCursor, true);
                      hasMore.current = _.get(store.getState().appState.pagination, targetKey)?.data?.hasMore;
                      const lengthViewData = _.get(store.getState().appState.pagination, targetKey)?.data?.length;

                      if (!hasMore.current && lengthViewData == 0) {
                        setCurrentPage(page - 1);
                      } else {
                        setCurrentPage(page);
                      }
                    }}
                  />
                )}

                {info?.showApiMode != "Hidden" && !dataSource?.splitViewConfig?.enabled && (
                  <Box display='flex' alignItems='center' border={"1px solid white"} marginInlineStart={2} paddingX={1.5} borderRadius={10}>
                    <Typography>API Mode</Typography>
                    <Switch onChange={(_, value) => setIsApiMode(value)} />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
          {info?.enableMultipleSelection && (
            <Stack direction='row' style={{ alignItems: "center" }}>
              <Checkbox color='primary' onChange={handleSelectAllChange} />
              <Typography> Rows</Typography>
            </Stack>
          )}
          {dataSource?.sourceType == "USER INPUT" && (
            <Box width='100%'>
              <UserInput
                queryKeys={queryKeys}
                pageId={pageId}
                setCurrentActionEndpoint={setCurrentActionEndpoint}
                userInputView={userInputView}
                __data={__data}
                refetch={refetchDataSource}
                views={views}
                setDataEntry={setDataEntry}
                setCurrentAction={setCurrentAction}
                viewName={info?.viewName}
              />
            </Box>
          )}
          <Grid container paddingY={2.5} spacing={"10px"}>
            {isApiMode ? (
              <ReactJson src={entries as any} theme={"paraiso"} style={{ width: "100%" }} />
            ) : (
              <Grid item xs={12} container spacing={1}>
                {!_.isEmpty(entries) && (
                  <>
                    {config?.repeatedViews?.map((repeatedView: any, repeatedViewIndex: any) => {
                      const repeatedViewLayout = config?.repeatedViewLayout;
                      const builderView = views?.find(view => view?.id == repeatedView?.viewBuilderId);
                      return repeatedView?.type == "repeated-view" ? (
                        isFixedHeight ? (
                          <Box height={"100%"} width={"100%"} overflow={"hidden"} key={builderView?.id}>
                            <Paper sx={{ height: "100%", width: "100%" }}>
                              <VirtuosoGrid
                                endReached={loadMore}
                                totalCount={entries?.length || 0}
                                components={components}
                                context={{ repeatedViewLayout }}
                                useWindowScroll
                                customScrollParent={parentRef?.current}
                                itemContent={index => (
                                  <BXRepeatedViewItem
                                    order={config?.order}
                                    path={path}
                                    __data={__data}
                                    item={entries[index]}
                                    index={index}
                                    pageId={pageId}
                                    views={views}
                                    info={info}
                                    builderView={builderView}
                                    isSelected={index === selectedViewIndex}
                                    repeatedViewLayout={repeatedViewLayout}
                                    selectedRows={selectedRows}
                                    handleDragHover={handleDragHover}
                                    handleUpdateElement={handleUpdateElement}
                                    handleSelectRow={handleSelectRow}
                                    setSelectedRows={setSelectedRows}
                                  />
                                )}
                              />
                            </Paper>
                            {(isFetching || (!!entries?.length && hasNextPage)) && (
                              <Grid item xs={12}>
                                <CircularProgress />
                              </Grid>
                            )}
                          </Box>
                        ) : (
                          entries?.map?.((item: any, index: number) => {
                            return (
                              <BXRepeatedViewItem
                                order={config?.order}
                                __data={__data}
                                item={item}
                                index={index}
                                key={index}
                                pageId={pageId}
                                views={views}
                                info={info}
                                builderView={builderView}
                                isSelected={index === selectedViewIndex}
                                repeatedViewLayout={repeatedViewLayout}
                                selectedRows={selectedRows}
                                handleDragHover={handleDragHover}
                                handleUpdateElement={handleUpdateElement}
                                handleSelectRow={handleSelectRow}
                                setSelectedRows={setSelectedRows}
                                path={path}
                              />
                            );
                          })
                        )
                      ) : (
                        <BXRepeatedViewItem
                          order={config?.order}
                          __data={__data}
                          repeatedViewLayout={{ xs: 12 }}
                          item={repeatedView}
                          index={repeatedViewIndex}
                          pageId={pageId}
                          views={views}
                          info={info}
                          builderView={builderView}
                          selectedRows={selectedRows}
                          isSelected={repeatedViewIndex === selectedViewIndex}
                          handleDragHover={handleDragHover}
                          handleUpdateElement={handleUpdateElement}
                          handleSelectRow={handleSelectRow}
                          setSelectedRows={setSelectedRows}
                          path={path}
                        />
                      );
                    })}
                  </>
                )}

                {((_.isEmpty(entries) && !isFetching) || isError) && (
                  <Grid item xs={12}>
                    <Typography component='div' align='center'>
                      {dataSource?.sourceType != "TABLE" ? null : `Select Data from ${dataSource?.table?.info?.name} Table`}
                    </Typography>
                  </Grid>
                )}
                {(isFetching || (!!entries?.length && hasNextPage)) && (
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <CircularProgress />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
