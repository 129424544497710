import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";

// routes
import { decompressData } from "src/utils/services";
import { useBXContext } from "../BXEngine/BXContext";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();
  const currentURL = useRef<string>();

  const { allowedApps, currentApp, loadingApps, setCurrentApp, setAppDescriptor, fqdnApp } = useBXContext();
  const loadingRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  // if (
  //   !loadingApps &&
  //   !currentApp &&
  //   window.location.pathname != "/" &&
  //   window.location.pathname != "/buildx/app" &&
  //   window.location.pathname != "/buildx/users" &&
  //   window.location.pathname != "/buildx/policies" &&
  //   window.location.pathname != "/buildx/roles" &&
  //   window.location.pathname != "/buildx/media" &&
  //   window.location.pathname != "/buildx/component" &&
  //   window.location.pathname != "/buildx/templates" &&
  //   window.location.pathname != "/buildx/oas" &&
  //   window.location.pathname != "/buildx/env" &&
  //   window.location.pathname != "/buildx/migrations" &&
  //   !window.location.pathname.startsWith("/buildx/form-builder") &&
  //   !window.location.pathname.startsWith("/buildx/page-builder")
  // ) {
  //   navigate("/");
  // }

  useEffect(() => {
    const _currentApp = allowedApps?.find((app?: BXApp) => {
      const appPath = fqdnApp ? "/" : `/${app?.slug}`;

      return app?.id === location.pathname.split("/")?.[3] || appPath === `/${location.pathname.split("/")[1]}`;
    });

    if (_currentApp) {
      setCurrentApp(_currentApp);
    }
  }, [allowedApps, location, setCurrentApp]);

  if (!currentURL.current) {
    currentURL.current = window.location.pathname;
  }

  if (
    allowedApps?.length &&
    !loadingApps &&
    currentApp &&
    window.location.pathname == "/" &&
    isLoggedIn &&
    !!currentApp?.templateConfig?.collections?.[0]?.pages?.length
  ) {
    const firstApp = currentApp;
    const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
      (collection: BXAppCollection) => collection.pages.length > 0
    );
    const collectionSlug = firstCollectionToHavePages?.slug;
    const firstPageSlug = firstCollectionToHavePages?.pages?.filter(page => page?.info?.visibility !== "Hidden")?.[0]?.slug;
    const routes = MainRoutes(allowedApps);
    const landingPageUri = currentApp?.appConfig?.pageUri;
    if (firstPageSlug) {
      const pathPrefix = fqdnApp ? "" : `${firstApp.slug}`;
      navigate({
        pathname: `${pathPrefix}/${landingPageUri || `${collectionSlug}/${firstPageSlug}`}`.replaceAll(/\/+/g, "/"),
      });
    } else if (!!routes?.[0]) navigate({ pathname: routes?.[0]?.path || "/" });
  }

  if (allowedApps?.length && currentApp && currentApp?.templateConfig == null && !loadingApps && !loadingRef.current && isLoggedIn) {
    loadingRef.current = true;
    //Store the actual URL to be resolved if available in the app and navigate to it

    axiosServices
      .get("/application/" + currentApp?.id)
      .then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.templateConfig) {
          data.templateConfig = decompressData(data?.templateConfig);
        }
        setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
        setCurrentApp(data);
        const firstApp = data;
        const firstCollectionToHavePages = firstApp.templateConfig?.collections?.find(
          (collection: BXAppCollection) => collection.pages.length > 0
        );
        if (window.location.pathname == data.slug) {
          const pathPrefix = fqdnApp ? "" : firstApp.slug;
          const collectionSlug = firstCollectionToHavePages?.slug;
          const firstPageSlug = firstCollectionToHavePages?.pages?.filter(page => page?.info?.visibility !== "Hidden")?.[0]?.slug;
          if (firstPageSlug) {
            navigate({ pathname: `${pathPrefix}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
          } else {
            navigate({ pathname: `${pathPrefix}`.replaceAll(/\/+/g, "/") });
          }
        }
      })
      .finally(() => {
        loadingRef.current = false;
        navigate({ pathname: currentURL.current });
      });
  }
  return useRoutes([...LoginRoutes, ...MainRoutes(allowedApps, currentApp, isLoggedIn, loadingApps)]);
}
