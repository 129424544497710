import { useNavigate } from "react-router-dom";

// project imports
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import useAuth from "src/hooks/useAuth";

// ==============================|| AUTH GUARD ||============================== //

type AuthGuardProps = {
  children: React.ReactNode;
  forbid?: string[];
};

/**
 * Authentication guard for routes
 * @param children children element/node
 * @param roles
 */

const isUnprotectedPage = (path: string, unprotectedPages: Set<string>): boolean => {
  const pathSlugs = path.split("/").filter(Boolean);
  const [collectionName, pageName] = pathSlugs;
  const fqdnURL = `${collectionName?.split("/").join("/")}/${pageName}`.replace(/\/+/g, "/");
  return unprotectedPages.has(fqdnURL);
};

const AuthGuard: FC<AuthGuardProps> = ({ children, forbid = [] }) => {
  const { isLoggedIn, user, isSuperAdmin } = useAuth();
  const { unprotectedPages } = useBXContext();
  const navigate = useNavigate();
  // if role is not included in user, navigate to main
  useEffect(() => {
    const currentPath = window.location.pathname;

    // If the current page is unprotected, allow access
    if (isUnprotectedPage(currentPath, unprotectedPages.current)) {
      return;
    }

    if (!isLoggedIn) {
      navigate("/login", { replace: true });
      window.open("/login", "_self");
    }
    if (
      _.isArray(forbid) &&
      _.intersectionWith(
        user?.roles?.map(role => role.id),
        forbid,
        _.isEqual
      ).length > 0 &&
      !isSuperAdmin()
    ) {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, navigate, forbid, isSuperAdmin, user]);

  return <>{children}</>;
};

export default AuthGuard;
