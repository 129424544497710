import { Box, Divider, Typography, useTheme } from "@mui/material";
import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { useDragLayer } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import { flattenItems, getContainerDimensions, getItemClosestProp, snapToStepperGrid } from "./utils";

const layerStyles: any = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 1000,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const getItemStyles = (
  initialOffset: any,
  currentOffset: any,
  initialClientOffset?: any,
  boxPosition?: any,
  difference?: any,
  factors?: any,
  isSideMenu = false,
  isStepperEnabled = false,
  hasCenterLines = false
) => {
  if (!difference || !initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let startX, startY, isItemInY, isItemInX;
  const { x: initialX, y: initialY } = initialOffset;
  const { x: clientX, y: clientY } = initialClientOffset;
  let { x, y } = currentOffset;

  // if (hasCenterLines) {
  // }

  if (isStepperEnabled) {
    x = x - clientX;
    y = y - clientY;
    [x, y] = snapToStepperGrid(x, y);
    x = x + clientX;
    y = y + clientY;
  }

  startX = Math.round(x - clientX + initialX);
  startY = Math.round(y - clientY + initialY);

  isItemInY = (y - boxPosition?.y) % 10 ? 1 : 1;
  isItemInX = (x - boxPosition?.x) % 10 ? 1 : 1;

  const translateX = Math.round(startX / isItemInX) * isItemInX;
  const translateY = Math.round(startY / isItemInY) * isItemInY;

  const transform = `translate(${translateX + (factors?.x || 0)}px, ${translateY + (factors?.y || 0)}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

export const CustomDragLayer: FC<{
  elements: any;
  selectedItemsId: any;
  layoutBreak: string;
  boxPosition: any;
  canDrop: boolean;
  isStepperEnabled: boolean;
  factorsRef?: any;
}> = memo(({ factorsRef, elements, selectedItemsId, canDrop, boxPosition, layoutBreak, isStepperEnabled }) => {
  const [factors, setFactors] = useState(factorsRef.current);
  const [hasCenterLines, setHasCenterLines] = useState<boolean>(false);
  const theme = useTheme();

  const { isDragging, item, currentOffset, initialOffset, initialClientOffset, differenceValues } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    clientSourceOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    initialClientOffset: monitor.getInitialClientOffset(),
    differenceValues: monitor.getDifferenceFromInitialOffset(),
  }));

  const isGroupDragging = isDragging && item.length > 1;
  const calculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const hasPerformedCalculationRef = useRef<boolean>(false);
  const [elementsPoints, setElementsPoints] = useState<any>([]);
  const [distanceList, setDistanceList] = useState<any>([]);
  const [lines, setLines] = useState<any>([]);
  const [textLinesList, setTextLinesList] = useState<any>([]);
  const difference = useMemo(() => differenceValues, [differenceValues]);
  const sameOrientationVerticalLinesArray: any[] = [];
  const sameOrientationHorizontalLinesArray: any[] = [];
  const factorNumberOne = 1;
  const calculateLines = () => {
    hasPerformedCalculationRef.current = true;
    if (!elementsPoints?.length) return;
    let linesList: any = [];
    let textLinesList: any = [];
    let factorsNumber = { x: 0, y: 0 };
    if (!initialClientOffset || !initialOffset || !currentOffset || !difference || item?.isSideMenu) {
      return;
    }
    const startX = Math.round(currentOffset.x - initialClientOffset.x + initialOffset.x);
    const startY = Math.round(currentOffset.y - initialClientOffset.y + initialOffset.y);

    const newItem = item?.length ? getContainerDimensions(items, true, layoutBreak) : item;
    const draggedItemTop = Math.round(startY - boxPosition?.y!);
    const draggedItemLeft = Math.round(startX - boxPosition?.x!);
    const draggedItemWidth = Math.round(getItemClosestProp(newItem?.config?.widthPx, layoutBreak) || newItem?.config?.defaultWidth);
    const draggedItemHeight = Math.round(getItemClosestProp(newItem?.config?.heightPx, layoutBreak) || newItem?.config?.defaultHeight);
    const draggedItemCenterX = draggedItemLeft + Math.round(draggedItemWidth / 2);
    const draggedItemCenterY = draggedItemTop + Math.round(draggedItemHeight / 2);
    const centerYMatches = elementsPoints?.filter((el: any) => Math.abs(el.centerY - draggedItemCenterY) <= 2);
    const centerXMatches = elementsPoints?.filter((el: any) => Math.abs(el.centerX - draggedItemCenterX) <= 2);

    const createLine = (el: any, orientation: "vertical" | "horizontal", direction: "left" | "right") => {
      if (orientation === "vertical") {
        return {
          positionLayout: "verticalCenter",
          top: (el?.top <= draggedItemTop ? el?.bottom : draggedItemTop + draggedItemHeight) + boxPosition?.y!,
          left: el?.centerX + boxPosition?.x!,
          height: el?.top <= draggedItemTop ? draggedItemTop - el?.top - el?.height : el?.top - draggedItemTop - draggedItemHeight,
          orientation: "vertical",
          direction: el?.top <= draggedItemTop ? "right" : "left",
        };
      } else {
        return {
          positionLayout: "horizontalCenter",
          left: (el?.left <= draggedItemLeft ? el?.right : draggedItemLeft + draggedItemWidth) + boxPosition?.x!,
          top: el?.centerY + boxPosition?.y!,
          width: el?.left <= draggedItemLeft ? draggedItemLeft - el?.left - el?.width : el?.left - draggedItemLeft - draggedItemWidth,
          orientation: "horizontal",
          direction: el?.left <= draggedItemLeft ? "right" : "left",
        };
      }
    };

    if (centerYMatches.length >= 2) {
      centerYMatches.forEach((el: any) => {
        textLinesList.push(createLine(el, "horizontal", el?.left <= draggedItemLeft ? "right" : "left"));
        linesList.push(createLine(el, "horizontal", el?.left <= draggedItemLeft ? "right" : "left"));
      });
    } else {
      elementsPoints.forEach((el: any) => {
        if (el?.centerY === draggedItemCenterY) {
          textLinesList.push(createLine(el, "horizontal", el?.left <= draggedItemLeft ? "right" : "left"));
          linesList.push(createLine(el, "horizontal", el?.left <= draggedItemLeft ? "right" : "left"));
        }
      });
    }

    if (centerXMatches.length >= 2) {
      centerXMatches.forEach((el: any) => {
        textLinesList.push(createLine(el, "vertical", el?.top <= draggedItemTop ? "right" : "left"));
        linesList.push(createLine(el, "vertical", el?.top <= draggedItemTop ? "right" : "left"));
      });
    } else {
      elementsPoints.forEach((el: any) => {
        if (el?.centerX === draggedItemCenterX) {
          textLinesList.push(createLine(el, "vertical", el?.top <= draggedItemTop ? "right" : "left"));
          linesList.push(createLine(el, "vertical", el?.top <= draggedItemTop ? "right" : "left"));
        }
      });
    }

    elementsPoints?.forEach((el: any) => {
      if (
        el?.centerX !== draggedItemCenterX &&
        el?.centerX !== draggedItemCenterX + 1 &&
        el?.centerX !== draggedItemCenterX - 1 &&
        el?.centerY !== draggedItemCenterY &&
        el?.centerY !== draggedItemCenterY + 1 &&
        el?.centerY !== draggedItemCenterY - 1
      ) {
        el?.top === draggedItemTop &&
          linesList.push({
            positionLayout: "verticalTopTop",
            left: (el?.left <= draggedItemLeft ? el?.left : draggedItemLeft) + boxPosition?.x!,
            top: el?.top + boxPosition?.y!,
            width: el?.left <= draggedItemLeft ? draggedItemLeft - el?.left + draggedItemWidth : el?.left - draggedItemLeft + el?.width,
            orientation: "horizontal",
          });
        el?.bottom === draggedItemTop + draggedItemHeight &&
          linesList.push({
            positionLayout: "verticalBottomBottom",
            left: (el?.left <= draggedItemLeft ? el?.left : draggedItemLeft) + boxPosition?.x!,
            top: el?.top + boxPosition?.y! + el?.height,
            width: el?.left <= draggedItemLeft ? draggedItemLeft - el?.left + draggedItemWidth : el?.left - draggedItemLeft + el?.width,
            orientation: "horizontal",
          });
        el?.bottom === draggedItemTop &&
          linesList.push({
            positionLayout: "verticalBottomTop",
            left: (el?.left <= draggedItemLeft ? el?.left : draggedItemLeft) + boxPosition?.x!,
            top: el?.top + boxPosition?.y! + el?.height,
            width: el?.left <= draggedItemLeft ? draggedItemLeft - el?.left + draggedItemWidth : el?.left - draggedItemLeft + el?.width,
            orientation: "horizontal",
          });
        el?.top === draggedItemTop + draggedItemHeight - 3 &&
          linesList.push({
            positionLayout: "verticalTopBottom",
            left: (el?.left <= draggedItemLeft ? el?.left : draggedItemLeft) + boxPosition?.x!,
            top: el?.top + boxPosition?.y!,
            width: el?.left <= draggedItemLeft ? draggedItemLeft - el?.left + draggedItemWidth : el?.left - draggedItemLeft + el?.width,
            orientation: "horizontal",
          });
        el?.left === draggedItemLeft &&
          linesList.push({
            positionLayout: "horizontalLeftLeft",
            top: (el?.top <= draggedItemTop ? el?.top : draggedItemTop) + boxPosition?.y!,
            left: el?.left + boxPosition?.x!,
            height: el?.top <= draggedItemTop ? draggedItemTop - el?.top + draggedItemHeight : el?.top - draggedItemTop + el?.height,
            orientation: "vertical",
          });
        el?.left === draggedItemLeft + draggedItemWidth - 3 &&
          linesList.push({
            positionLayout: "horizontalLeftRight",
            top: (el?.top <= draggedItemTop ? el?.top : draggedItemTop) + boxPosition?.y!,
            left: el?.left + boxPosition?.x!,
            height: el?.top <= draggedItemTop ? draggedItemTop - el?.top + draggedItemHeight : el?.top - draggedItemTop + el?.height,
            orientation: "vertical",
          });
        el?.left + el?.width === draggedItemLeft &&
          linesList.push({
            positionLayout: "horizontalRightLeft",
            top: (el?.top <= draggedItemTop ? el?.top : draggedItemTop) + boxPosition?.y!,
            left: el?.left + boxPosition?.x! + el?.width,
            height: el?.top <= draggedItemTop ? draggedItemTop - el?.top + draggedItemHeight : el?.top - draggedItemTop + el?.height,
            orientation: "vertical",
          });
        el?.left + el?.width === draggedItemLeft + draggedItemWidth &&
          linesList.push({
            positionLayout: "horizontalRightRight",
            top: (el?.top <= draggedItemTop ? el?.top : draggedItemTop) + boxPosition?.y!,
            left: el?.left + boxPosition?.x! + el?.width,
            height: el?.top <= draggedItemTop ? draggedItemTop - el?.top + draggedItemHeight : el?.top - draggedItemTop + el?.height,
            orientation: "vertical",
          });
      }

      factorsNumber =
        el?.top === draggedItemTop + Math.round(draggedItemHeight) - factorNumberOne
          ? { ...factorsNumber, y: +factorNumberOne }
          : factorsNumber;
      factorsNumber =
        el?.top + Math.round(el?.height) === draggedItemTop + factorNumberOne ? { ...factorsNumber, y: -factorNumberOne } : factorsNumber;
      factorsNumber =
        el?.top + Math.round(el?.height) === draggedItemTop - factorNumberOne ? { ...factorsNumber, y: +factorNumberOne } : factorsNumber;
      factorsNumber =
        Math.round(boxPosition?.width! / 2) === draggedItemLeft + Math.round(draggedItemWidth / 2) + factorNumberOne
          ? { ...factorsNumber, x: -factorNumberOne }
          : factorsNumber;
      factorsNumber =
        Math.round(boxPosition?.width! / 2) === draggedItemLeft + Math.round(draggedItemWidth / 2) - factorNumberOne
          ? { ...factorsNumber, x: +factorNumberOne }
          : factorsNumber;

      factorsNumber =
        Math.round((boxPosition?.height! || 800) / 2) === draggedItemTop + Math.round(draggedItemHeight / 2)
          ? { ...factorsNumber, y: -factorNumberOne }
          : factorsNumber;
      factorsNumber =
        Math.round((boxPosition?.height! || 800) / 2) === draggedItemTop + Math.round(draggedItemHeight / 2)
          ? { ...factorsNumber, y: +factorNumberOne }
          : factorsNumber;
      return;
    });

    Math.round(boxPosition?.width! / 2) === draggedItemLeft + Math.round(draggedItemWidth / 2) &&
      linesList.push({
        positionLayout: "verticalBoxCenter",
        top: boxPosition?.y!,
        left: boxPosition?.x! + Math.round(boxPosition?.width! / 2),
        height: boxPosition?.height!,
        orientation: "vertical",
      });
    Math.round((boxPosition?.height! || 800) / 2) === draggedItemTop + Math.round(draggedItemHeight / 2) &&
      linesList.push({
        positionLayout: "horizontalBoxCenter",
        left: boxPosition?.x!,
        top: boxPosition?.y! + Math.round((boxPosition?.height! || 800) / 2),
        width: boxPosition?.width || 800!,
        orientation: "horizontal",
      });

    linesList.forEach((line, lineIndex) => {
      const sameOrientationVerticalLines = linesList.filter(
        (item, index) => index !== lineIndex && line.orientation === "vertical" && Math.abs(item.left - line.left) < 4
      );

      const sameOrientationHorizontalLines = linesList.filter(
        (item, index) => index !== lineIndex && line.orientation === "horizontal" && Math.abs(item.top - line.top) < 4
      );

      sameOrientationVerticalLines.forEach(verticalLine => {
        if (!sameOrientationVerticalLinesArray.some(item => item === verticalLine)) {
          sameOrientationVerticalLinesArray.push(verticalLine);
        }
      });

      sameOrientationHorizontalLines.forEach(horizontalLine => {
        if (!sameOrientationHorizontalLinesArray.some(item => item === horizontalLine)) {
          sameOrientationHorizontalLinesArray.push(horizontalLine);
        }
      });
    });

    sameOrientationHorizontalLinesArray.forEach((line, index) => {
      const sameLinesWithLongestWidth = sameOrientationHorizontalLinesArray.filter((item, i) => i !== index && item.width >= line.width);

      if (sameLinesWithLongestWidth.length === 0) {
        linesList = linesList.map(item => (item === line ? { ...item, visibility: "visible" } : { ...item, visibility: "hidden" }));
      }
    });

    sameOrientationVerticalLinesArray.forEach((line, index) => {
      const sameLinesWithLongestHeight = sameOrientationVerticalLinesArray.filter((item, i) => i !== index && item.height >= line.height);

      if (sameLinesWithLongestHeight.length === 0) {
        linesList = linesList.map(item => (item === line ? { ...item, visibility: "visible" } : { ...item, visibility: "hidden" }));
      }
    });

    const newDistances = textLinesList.map(line => {
      if (line.orientation === "horizontal") {
        return Math.round(line?.width || 0);
      } else {
        return Math.round(line?.height || 0);
      }
    });

    const horizontalCenterExists = linesList.some((line: any) => line.positionLayout === "horizontalCenter");
    const verticalCenterExists = linesList.some((line: any) => line.positionLayout === "verticalCenter");
    const hasLines = horizontalCenterExists || verticalCenterExists;

    setHasCenterLines(hasLines);

    setFactors(factorsNumber);
    setLines(linesList);
    setTextLinesList(textLinesList);
    setDistanceList(newDistances);
  };

  const calculateElementsPoints = () => {
    if (!elements?.length) return;
    const allItems = flattenItems(elements);
    setElementsPoints(
      allItems
        ?.filter((el: any) => !selectedItemsId.includes(el?.id) && el?.id !== item?.id)
        ?.map((el: any) => {
          const itemReal = document.getElementById(el?.id)?.getBoundingClientRect();
          const itemTop = Math.round(itemReal?.y! - boxPosition?.y!);
          const itemLeft = Math.round(itemReal?.x! - boxPosition?.x!);
          const itemWidth = Math.round(itemReal?.width!);
          const itemHeight = Math.round(itemReal?.height!);
          return {
            top: itemTop,
            left: itemLeft,
            centerX: itemLeft + Math.round(itemWidth / 2),
            centerY: itemTop + Math.round(itemHeight / 2),
            bottom: itemTop + Math.round(itemHeight),
            right: itemLeft + Math.round(itemWidth),
            height: Math.round(itemHeight),
            width: Math.round(itemWidth),
          };
        })
    );
  };

  useEffect(() => {
    isDragging && calculateElementsPoints();
  }, [isDragging]);

  useEffect(() => {
    if (isGroupDragging || item?.isSideMenu) {
      setTextLinesList([]);
      setLines([]);
      setDistanceList([]);
    }
  }, [isGroupDragging, item?.isSideMenu]);

  useEffect(() => {
    calculationTimeoutRef.current = setTimeout(() => {
      calculateLines();
    }, 0);

    return () => {
      clearTimeout(calculationTimeoutRef.current);
      hasPerformedCalculationRef.current = false;
    };
  }, [Math.round(Number(difference?.x)), Math.round(Number(difference?.y))]);

  const items = item?.[0] ? item : [item];

  if (!isDragging) {
    return null;
  }

  return (
    <Box sx={layerStyles} dir='ltr'>
      {hasPerformedCalculationRef.current && (
        <>
          {lines?.map((line: any, index: any) => {
            const dividerLine = (
              <Box
                key={uuidv4()}
                sx={{
                  ...line,
                  position: "absolute",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: line?.orientation === "vertical" ? "row" : "column",
                }}
              >
                <Divider
                  sx={{
                    width: line?.orientation === "horizontal" ? "100%" : "unset",
                    height: line?.orientation === "vertical" ? "100%" : "unset",
                    orientation: line?.orientation,
                    border:
                      line?.positionLayout === "verticalBoxCenter" || line?.positionLayout === "horizontalBoxCenter"
                        ? "1px solid orange"
                        : "1px solid #0f0 !important",
                    opacity: 0.4,
                  }}
                />
              </Box>
            );

            const distanceForTypography = (
              <Box
                key={uuidv4()}
                sx={{
                  ...textLinesList[index],
                  position: "absolute",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: textLinesList[index]?.direction === "right" ? "flex-start" : "flex-end",
                  flexDirection: textLinesList[index]?.orientation === "vertical" ? "row" : "column",
                }}
              >
                {distanceList
                  .filter((item, mapIndex) => mapIndex !== index)
                  .map((item, mapIndex) => {
                    const distance = distanceList[index];
                    const correspondingElement = elementsPoints.find(el => el.id === item.id);
                    const isVertical = textLinesList[mapIndex]?.orientation === "vertical";
                    const isHorizontal = textLinesList[mapIndex]?.orientation === "horizontal";
                    const areAllElementsEqual =
                      (elementsPoints.every(el => el?.height === correspondingElement?.height) && isVertical) ||
                      (elementsPoints.every(el => el?.width === correspondingElement?.width) && isHorizontal);

                    let shouldRenderTypography = false;
                    let finalDistances: number[] = [];
                    let newDistanceValue;

                    if (areAllElementsEqual) {
                      if (distanceList.length === 2) {
                        newDistanceValue = isVertical
                          ? Math.round(Math.max(distance, item) - Math.min(distance, item) - correspondingElement?.height)
                          : isHorizontal
                          ? Math.round(Math.max(distance, item) - Math.min(distance, item) - correspondingElement?.width)
                          : 0;
                        shouldRenderTypography = Math.abs(newDistanceValue - distance) === 0 || Math.abs(newDistanceValue - item) === 0;
                      } else if (distanceList.length >= 3) {
                        const smallestValue = Math.min(...distanceList);
                        const remainingDistances = distanceList.filter(value => value !== smallestValue);
                        const calculatedDistances = remainingDistances.map((distance, index) => {
                          if (remainingDistances.length === 1) {
                            return remainingDistances[0];
                          } else {
                            const sortedDistances = [...remainingDistances, smallestValue].sort((a, b) => b - a);
                            const smallerDistances = sortedDistances.slice(1);

                            newDistanceValue = isVertical
                              ? Math.round(sortedDistances[0] - correspondingElement?.height - smallerDistances[mapIndex - 1])
                              : isHorizontal
                              ? Math.round(sortedDistances[0] - correspondingElement?.width - smallerDistances[mapIndex - 1])
                              : 0;
                            return newDistanceValue;
                          }
                        });
                        finalDistances = [smallestValue, ...calculatedDistances];
                        const areAllValuesEqual =
                          finalDistances?.length > 1 ? finalDistances?.every(value => value === finalDistances[0]) : true;
                        shouldRenderTypography = areAllValuesEqual;
                      }
                    } else if (!areAllElementsEqual) {
                      if (distanceList.length === 2) {
                        const smallestValue = Math.min(...distanceList);
                        const maxtValue = Math.max(...distanceList);
                        const allHeights = Array.from(new Set(elementsPoints.map(el => el.height))) as number[];
                        const allWidths = Array.from(new Set(elementsPoints.map(el => el.width))) as number[];
                        const possibleValues = isVertical ? allHeights : allWidths;
                        let calculatedDistances: number[] = [];

                        for (const item of possibleValues) {
                          const conditionResult = maxtValue - smallestValue - item === smallestValue;
                          if (conditionResult) {
                            calculatedDistances.push(maxtValue - smallestValue - item);
                            break;
                          }
                        }
                        const finalDistances = [smallestValue, ...calculatedDistances];
                        shouldRenderTypography =
                          finalDistances?.length > 1 ? finalDistances?.every(value => value === finalDistances[0]) : false;
                      }
                    }
                    return (
                      shouldRenderTypography && (
                        <Typography
                          key={uuidv4()}
                          style={{
                            paddingLeft: isVertical ? "4px" : "2px",
                            paddingTop: isVertical ? "0px" : "2px",
                          }}
                        >
                          {isVertical && textLinesList[mapIndex]?.positionLayout === "verticalCenter"
                            ? "V"
                            : isHorizontal && textLinesList[mapIndex]?.positionLayout === "horizontalCenter"
                            ? "H"
                            : ""}
                        </Typography>
                      )
                    );
                  })}

                {distanceList.map((item, mapIndex) => {
                  const sortedDistanceList = distanceList.sort((a, b) => b - a);
                  const distance = sortedDistanceList[mapIndex];
                  const isVertical = textLinesList[mapIndex]?.orientation === "vertical";
                  const correspondingElement = elementsPoints.find(el => el.id === item.id);
                  const isHorizontal = textLinesList[mapIndex]?.orientation === "horizontal";
                  const areAllElementsEqual =
                    (elementsPoints.every(el => el?.height === correspondingElement?.height) && isVertical) ||
                    (elementsPoints.every(el => el?.width === correspondingElement?.width) && isHorizontal);
                  let shouldRenderTypography = false;

                  if (distanceList.length >= 3 && !areAllElementsEqual) {
                    const smallestValue = Math.min(...distanceList);
                    const allHeights = Array.from(new Set(elementsPoints.map(el => el.height))) as number[];
                    const allWidths = Array.from(new Set(elementsPoints.map(el => el.width))) as number[];
                    const possibleValues = isVertical ? allHeights : allWidths;
                    let calculatedDistances: number[] = [];

                    for (const item of possibleValues) {
                      const conditionResult1 = Math.abs(distance - sortedDistanceList[1] - item - smallestValue) <= 1;
                      const conditionResult2 = Math.abs(distance - smallestValue - item - smallestValue) <= 1;

                      if (conditionResult1) {
                        calculatedDistances.push(distance - sortedDistanceList[1] - item);
                      }

                      if (conditionResult2) {
                        calculatedDistances.push(distance - smallestValue - item);
                      }
                    }
                    for (const item of possibleValues) {
                      const conditionResult = Math.abs(distance - smallestValue - item - smallestValue) <= 1;
                      if (conditionResult) {
                        calculatedDistances.push(distance - smallestValue - item);
                      }
                    }

                    const finalDistances = [smallestValue, ...calculatedDistances];
                    const areAllValuesEqual =
                      finalDistances?.length > 2 ? finalDistances?.every(value => Math.abs(value - finalDistances[0]) <= 1) : false;
                    shouldRenderTypography = areAllValuesEqual;
                  }

                  const renderTypography = () => {
                    return (
                      shouldRenderTypography && (
                        <Typography
                          key={uuidv4()}
                          style={{
                            paddingLeft: isVertical ? "4px" : "2px",
                            paddingTop: isVertical ? "0px" : "2px",
                          }}
                        >
                          {isVertical && textLinesList[mapIndex]?.positionLayout === "verticalCenter"
                            ? "V"
                            : isHorizontal && textLinesList[mapIndex]?.positionLayout === "horizontalCenter"
                            ? "H"
                            : ""}
                        </Typography>
                      )
                    );
                  };

                  return <React.Fragment key={mapIndex}>{renderTypography()}</React.Fragment>;
                })}

                {distanceList
                  .filter((item, mapIndex) => mapIndex !== index)
                  .map((item, mapIndex) => {
                    const distance = distanceList[index];
                    const renderTypography = () => {
                      return (
                        distanceList.length === 2 && (
                          <>
                            {distanceList
                              .filter((item, mapIndex) => mapIndex !== index && textLinesList[mapIndex]?.orientation === "vertical")
                              .some(item => Math.abs(item - distance) < 2) && (
                              <Typography style={{ paddingLeft: "4px", paddingTop: "0px" }}>V</Typography>
                            )}
                            {distanceList
                              .filter((item, mapIndex) => mapIndex !== index && textLinesList[mapIndex]?.orientation === "horizontal")
                              .some(item => Math.abs(item - distance) < 2) && (
                              <Typography style={{ paddingLeft: "2px", paddingTop: "2px" }}>H</Typography>
                            )}
                          </>
                        )
                      );
                    };

                    return <React.Fragment key={mapIndex}>{renderTypography()}</React.Fragment>;
                  })}
              </Box>
            );

            return (
              <React.Fragment key={index}>
                {dividerLine}
                {distanceForTypography}
              </React.Fragment>
            );
          })}
        </>
      )}

      {items?.map((el: any, index: any) => {
        const itemReal = document.getElementById(el?.id)?.getBoundingClientRect();
        if (!el?.config) return;
        const { defaultHeight, defaultWidth } = el?.config;

        return (
          <Box
            key={el?.id || index}
            style={{
              ...getItemStyles(
                initialOffset,
                currentOffset,
                initialClientOffset,
                el?.boxPosition,
                difference,
                factors,
                item?.isSideMenu,
                isStepperEnabled,
                hasCenterLines
              ),
            }}
          >
            <Box
              style={{
                position: item?.[0] ? "absolute" : "unset",
                left: item?.[0] ? (el?.level == 1 ? itemReal?.x! - boxPosition?.x! : itemReal?.x) : "unset",
                top: item?.[0] ? (el?.level == 1 ? itemReal?.y! - boxPosition?.y! : itemReal?.y) : "unset",
                width: itemReal?.width || defaultWidth,
                height: itemReal?.height || defaultHeight,
                backgroundColor: canDrop ? theme.palette.success.main : theme.palette.error.main,
                opacity: 0.5,
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
});
